import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

{/* Add Your Global Styles */}

body {
    margin: 0;
    padding: 0;
    background: #EFEFEF;
  }
`;

export default GlobalStyle;